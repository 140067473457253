import React from 'react';
import { useColorMode } from 'theme-ui';

const LightSwitch = (): JSX.Element => {
  const [colorMode, setColorMode] = useColorMode();
  const isDark = colorMode === 'dark';
  const toggleColorMode = () => {
    setColorMode(isDark ? 'light' : 'dark');
  };

  return (
    <div style={{ width: '20px'}} onClick={toggleColorMode} onKeyPress={toggleColorMode} role="button" tabIndex={0}>
      {isDark ? '💡' : '🌙'}
    </div>
  );
};

export default LightSwitch;
