import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Hi, I'm Dan 👋`}</h2>
    <p>{`I'm a full-stack software engineer based in the UK`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      