import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/dan-vernon"
      }}>{`GitHub`}</a>{` | `}<a parentName="h3" {...{
        "href": "https://www.linkedin.com/in/dan-vernon-a9843165/"
      }}>{`LinkedIn`}</a></h3>
    <hr></hr>
    <h3>{`What I do now ->`}</h3>
    <h5>{`Full-Stack Software Engineer @ `}<a parentName="h5" {...{
        "href": "https://creditkudos.com/"
      }}>{`Credit Kudos`}</a></h5>
    <p>{`Working with the Open Banking protocol across predominantly Rails & React/TypeScript stack.`}</p>
    <hr></hr>
    <p>{`My life pre-coding was on the business & operations side of high-growth London tech startups. Working in high-achieving, cross-functional teams in tandem with product and tech teams left me eager to learn more about the force mutliplier effect of software engineering.`}</p>
    <p>{`My first contact with web development had been in the late 90s, when, having broken out of AOL's walled garden, I taught myself the basics of HTML and CSS, contributing my own esoteric eyesores to the Geocities community and racking up horrendous dial-up phone bills in the process. Sorry, dad.`}</p>
    <p>{`In 2019, a mere 20 years after first starting my coding career, I enrolled at LeWagon's 450-hour intensive full-stack coding bootcamp. With their focus on rapid prototyping using Ruby on Rails, I learnt the fundamentals of software development using OOP, as well as experiencing first-hand the intricacies of collaboratively developing apps for the modern web.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      