import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Work`}</h2>
    <ProjectCard title="luismulet.photo" link="https://luismulet.photo" bg="linear-gradient(180deg, rgba(10,10,10,0.8) 0%, #8c7ae699 100%), url('https://res.cloudinary.com/danvernon/image/upload/v1580677469/dv-gatsby/lmphoto.png')" mdxType="ProjectCard">
Custom-built interiors photography portfolio site built in React bootstrapped from CRA.
    </ProjectCard>
    <ProjectCard title="Seekrs" link="https://seekrs.herokuapp.com/" bg="linear-gradient(180deg, rgba(10,10,10,0.8) 0%, #8c7ae699 100%), url('https://res.cloudinary.com/danvernon/image/upload/q_auto:good/v1556062192/dv-gatsby/seekrs.png')" mdxType="ProjectCard">
Final group project at Le Wagon bootcamp built on Rails. Features Stripe, Mapbox & Algolia integrations as well as live chat powered by ActionCable sockets.
    </ProjectCard>
    <ProjectCard title="ZeroPlusOne" link="https://zeroplusone.herokuapp.com/" bg="linear-gradient(180deg, rgba(10,10,10,0.8) 0%, #8c7ae699 100%), url('https://res.cloudinary.com/danvernon/image/upload/q_auto:good/v1556062194/dv-gatsby/zeroplusone.png')" mdxType="ProjectCard">
Group project at Le Wagon bootcamp built on Rails. 
    </ProjectCard>
    <ProjectCard title="Pomodoro" link="https://dv-pomodoro.netlify.com/" bg="linear-gradient(180deg, rgba(10,10,10,0.8) 0%, #8c7ae699 100%), url('https://res.cloudinary.com/danvernon/image/upload/v1557338933/dv-gatsby/pomodoro.png')" mdxType="ProjectCard">
Simple TDD-built exercise in React.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      